import dynamic from "next/dynamic";
import Typography from "@mui/material/Typography";
import { getFooterName } from "@components/config/helpers";
import useGlobalSettings from "@components/_hooks/useGlobalSettings";

const FooterAccordion = dynamic(() => import("./FooterAccordion"))
const FooterLink = dynamic(() => import("./FooterLink"))

const FooterInformation = ({links, type, className}) => {
 
    const section_name = getFooterName(links, 'Information');
    const {getSetting} = useGlobalSettings();
    
    return (
        type == 'desktop' ? (
            <>
                <Typography className={`${getSetting('footer_section_classes', 'f-size_3 mb-3 uppercase font-regular')}`} component="h5">{section_name}</Typography>
                <ul className="font-light">
                    {
                        links.map((link, index) => {
                            return (
                                <FooterLink
                                    link={link}
                                    key={"info-link-" + index}
                                    className={className}
                                />
                            );
                        })
                    }
                </ul>
            </>

        ) : (
            <FooterAccordion classes="fw-bold font-regular" title={section_name} accordionType="information" type="footer">
                <ul className="font-light">
                    {
                        links.map((link, index) => {
                            return (
                                <FooterLink
                                    link={link}
                                    key={"company_link-" + index}
                                />
                            );
                        })
                    }
                </ul>
            </FooterAccordion>
        )
    );
};

export default FooterInformation;
